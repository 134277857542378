let viewer_div = document.getElementById("viewer")
setup()

function setup() {
  var look_insides = d3.selectAll(".bna-title-cover__media__icon, .bna-title-cover__action")
  look_insides.on("click", () => {
    d3.selectAll(".bna-title-cover__media img.cover").remove()
    // d3.select(".look_inside span").remove()
    d3.select(".bna-title-cover__media__icon i").remove()
    d3.select(".bna-title-cover__action").remove()

    d3.select(".bna-title-cover__media__icon").append("i").attr("class", "spinner")
    // d3.select(".bna-title-cover__media__icon").attr("class", "bna-title-cover__media__icon loading")
    spawn_viewer()
  })
}

function spawn_viewer() {
  var tile_sources = []
  var image_id = viewer_div.dataset.image
  var page_count = viewer_div.dataset.pagecount
  for (let i = 1; i <= page_count; i++) {
    tile_sources.push({url: `/titles/images/${image_id}/${i}`, type: "image"})
  }
  var viewer = new OpenSeadragon({
    id: "viewer",
    //homeFillsViewer: true,
    sequenceMode: true,
    navPrevNextWrap: true,
    navigationControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
    sequenceControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
    tileSources: tile_sources
  })
  viewer.addHandler('open', () => { d3.select(".bna-title-cover__media__icon").remove() })
}
